"use client";

import React, { useContext } from "react";
import classNames from "classnames";
import { HeroProps } from "./types";
import { Typography } from "../Typography";
import { Link } from "../Link";
import { AmplitudeContext } from "@/contexts/AmplitudeContext/AmplitudeContext";

import { NextImage } from "../NextImage";
import "./styles.scss";

export const Hero = ({ heroText, cta, img, reverse, className }: HeroProps) => {
  const classnames = classNames(`Hero__container`, {
    [`Hero__container--reverse`]: reverse,
    [`Hero__container--${className}`]: className,
  });

  const { setSource } = useContext(AmplitudeContext);

  return (
    <div
      className={classnames}
      data-testid="Hero">
      <div className="Hero__column--left">
        {heroText?.map((item, i: number) => (
          <Typography
            key={i}
            Tag={item.tag}
            size={item.size}
            spacing={item.spacing}>
            {item.list
              ? item.list.map((e, i) => (
                  <li key={i}>{<span dangerouslySetInnerHTML={{ __html: e["item"] }}></span>}</li>
                ))
              : item.content}
          </Typography>
        ))}

        {cta && (
          <Link
            aria-label="cta-hero-banner"
            dataQA={cta.data_qa}
            href={cta.ctaLink}
            className="bold-text cta-hero-banner-btn large button"
            type="button"
            onClick={() => setSource("cta_hero_banner")}
            nofollow
            rounded={!!cta.ctaStyle}>
            {cta.ctaLabel}
          </Link>
        )}
      </div>
      <div className="Hero__column--right">
        <NextImage
          src={img}
          alt="qr grid selection"
          className="hero-img"
          width={633}
          height={379}
        />
        {cta && (
          <div className="cta-mobile">
            <Link
              aria-label="cta-hero-banner"
              dataQA={cta.data_qa}
              href={cta.ctaLink}
              className="bold-text cta-hero-banner-btn-mobile large button"
              type="button"
              onClick={() => setSource("cta_hero_banner")}
              nofollow
              rounded={!!cta.ctaStyle}>
              {cta.ctaLabel}
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};
