import React from "react";

import { Typography } from "@/components/Typography";
import { Preview } from "@/components/Preview";
import { Banner } from "@/components/Banner";

import { previewHome } from "@/data/types/home";
import { BannerWrapperSize } from "@/components/Banner/types";

export const GridModule = ({
  data,
  wrapperSize,
}: {
  data: previewHome;
  wrapperSize: BannerWrapperSize;
}) => {
  const { items, subtitle, title, data_qa, backgroundColor, ctaStyle } = data;

  return (
    <Banner
      small
      light
      customBgColor={backgroundColor}
      wrapperSize={wrapperSize}
      customClass="banner-preview">
      <Typography
        Tag={"h2"}
        spacing={4}>
        {title}
      </Typography>
      <Typography
        Tag={"p"}
        size="l"
        spacing={4}>
        {subtitle}
      </Typography>
      <Preview
        items={items}
        dataQA={data_qa}
        ctaStyle={ctaStyle}
      />
    </Banner>
  );
};
