"use client";
import React, { useContext } from "react";
import dynamic from "next/dynamic";

import { Banner } from "@/components/Banner";
import { Link } from "../Link";
import { Typography } from "@/components/Typography";
import { BannerWrapperSize } from "@/components/Banner/types";
import { featuredProps } from "@/data/types/home";
import { AmplitudeContext } from "@/contexts/AmplitudeContext/AmplitudeContext";

// Fix Warning: Prop 'className' did not match. Import component by turning off ssr
const Featured = dynamic(() => import("@/components/Featured").then((mod) => mod.Featured), {
  ssr: false,
});

export const ThreeStepsModule = ({
  data,
  wrapperSize,
}: {
  data: featuredProps;
  wrapperSize: BannerWrapperSize;
}) => {
  const { List, Title, ctaLabel, ctaLink, data_qa, backgroundColor, customClass, ctaStyle } = data;
  const { setSource } = useContext(AmplitudeContext);

  return (
    <Banner
      big
      light
      customBgColor={backgroundColor}
      wrapperSize={wrapperSize}>
      <Typography
        Tag={"h2"}
        spacing={8}>
        {Title}
      </Typography>
      <Featured
        items={List}
        customClass={customClass}
      />
      <Link
        dataQA={data_qa}
        href={ctaLink}
        className="bold-text large button"
        type="button"
        onClick={() => setSource("cta_steps")}
        nofollow
        rounded={!!ctaStyle}>
        {ctaLabel}
      </Link>
    </Banner>
  );
};
